
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.banner {
  position: relative;

  @include desktop {
    height: 162px;
    margin-top: 64px;
  }

  @include mobile {
    height: 106px;
    margin-top: 32px;
  }

  .loader {
    height: 100%;
  }
}
