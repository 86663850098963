
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.tooltip {
  display: inline-block;
  align-items: center;
  background-color: rgb(var(--jet));
  color: rgb(var(--white));
  padding: 8px 8px;
  border-radius: var(--border-radius);
  position: absolute;
  height: 30px;
  right: 50px;
  top: 5px;
  line-height: 1;
  white-space: nowrap;
  z-index: 2;
}

.arrow {
  background-color: rgb(var(--jet));
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
  height: 15px;
  width: 10px;
  clip-path: polygon(0% 0%, 100% 50%, 0% 100%);
}
