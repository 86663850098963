
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

@mixin margin-block($value) {
  /* native css margin-block is only supported in iOS>14.4 */
  margin-top: $value;
  margin-bottom: $value;
}

.mainContent {
  @include desktop {
    margin-top: 32px;
  }
  @include mobile {
    margin-top: 0;
  }

  .defaultSection {
    @include desktop {
      margin-top: 64px;
    }
    @include mobile {
      margin-top: 32px;
    }

    header {
      margin-bottom: 8px;
      .title {
        font-weight: var(--font-weight-regular);
        margin-bottom: 16px;

        @include desktop {
          font-size: var(--font-size-xl);
        }
        @include mobile {
          font-size: var(--font-size-m);
        }
      }
    }
  }

  .banner {
    padding: 0;
    margin-bottom: 32px;
  }

  .latestSearches {
    background-color: rgb(var(--snow));
    display: flex;

    @include desktop {
      padding: 28px;
      border-radius: 8px;
      flex-direction: row;
      margin-block: 64px;
    }
    @include mobile {
      padding: 16px;
      flex-direction: column;
      @include margin-block(32px);
    }

    header {
      font-weight: var(--font-weight-medium);
      flex-grow: 0;

      @include desktop {
        margin-inline-end: 40px;
      }
      @include mobile {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        width: 100%;
        margin-bottom: 16px;
      }

      .title {
        @include desktop {
          font-size: var(--font-size-xl);
          margin-bottom: 4px;
        }
        @include mobile {
          font-size: var(--font-size-m);
        }
      }

      .link {
        color: rgb(var(--safetyOrange));

        .icon {
          @include iconColor(rgb(var(--safetyOrange)));
        }
      }
    }

    main {
      flex: 1;
      overflow-wrap: anywhere;
    }
  }

  .bushiness {
    background-color: rgb(var(--snow));
    display: flex;

    @include desktop {
      padding: 38px;
      border-radius: 8px;
      flex-direction: row;
      margin-block: 64px;
      align-items: center;
    }

    @include mobile {
      padding: 20px 15px;
      flex-direction: column;
      @include margin-block(32px);
    }
    header {
      font-weight: var(--font-weight-medium);
      flex-grow: 0;

      @include desktop {
        margin-inline-end: 72px;
      }
      @include mobile {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        width: 100%;
        margin-bottom: 20px;
      }

      .title {
        @include desktop {
          font-size: var(--font-size-xxl);
        }
        @include mobile {
          font-size: var(--font-size-m);
        }
      }
    }

    main {
      flex: 1;
    }
  }

  .additionalLinks {
    margin-top: 16px;

    header {
      margin-bottom: 15px;
    }

    .additionalLinksContainer {
      @include desktop {
        display: grid;
        grid-template-columns: 1fr 290px;
      }
      @include mobile {
        display: block;
      }
    }
  }
}

.alternativeBackground {
  background-color: rgb(var(--snow));
  border: 1px solid transparent; // hack so height is affected by children margins

  @include desktop {
    padding: 16px 0px 60px 0px;
    min-height: 408px;
  }

  @include mobile {
    padding: 30px 0px;
  }
}
