
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.item {
  position: relative;

  .likeButton {
    z-index: 1;
  }

  .card {
    margin-inline: 0 !important;
    margin-bottom: 8px !important;
    overflow: hidden;
    position: relative;

    .image {
      @include desktop {
        height: 150px;
      }

      @include mobile {
        height: 90px;
      }
    }

    .badgeArea {
      position: absolute;
      bottom: 8px;
      right: 8px;
      display: grid;
      grid-auto-flow: column;
      gap: 8px;

      .badge {
        background-color: rgba(0, 0, 0, 0.5);
        padding: 2px 6px;
        border-radius: 4px;
        font-size: 12px;
        color: rgb(var(--white));

        &.tradeIn {
          background-color: rgb(var(--caribbeanGreen));
        }
      }
    }

    .text {
      padding: 12px 10px 8px;

      * {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        height: 1em; // prevent height from collapsing when no text content
      }

      .row1 {
        font-weight: var(--font-weight-medium);
        font-size: var(--font-size-s);
      }

      .row2 {
        font-size: var(--font-size-xs);
        margin-top: 4px;
      }

      .row3 {
        font-size: var(--font-size-xxs);
        margin-top: 12px;
      }

      @include mobile {
        .row1 {
          font-weight: var(--font-weight-medium);
          font-size: var(--font-size-xs);
        }

        .row2 {
          font-size: var(--font-size-xxs);
        }

        .row3 {
          font-size: var(--font-size-xxs);
        }
      }
    }
  }
}
