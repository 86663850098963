
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.container {
  display: flex;
  gap: 12px;
}

.imageSkeleton {
  @include desktop {
    height: 150px;
    width: 290px;
  }

  @include mobile {
    height: 90px;
    width: 140px;
  }
}

.row1Skeleton {
  margin-top: 14px;

  @include desktop {
    margin-right: 10px;
    height: 15px;
    width: 158px;
  }

  @include mobile {
    margin-right: 5px;
    height: 15px;
    width: 104px;
  }
}

.row2Skeleton {
  @include desktop {
    margin-top: 10px;
    margin-right: 10px;
    height: 10px;
    width: 180px;
  }

  @include mobile {
    margin-top: 14px;
    margin-right: 5px;
    height: 10px;
    width: 119px;
  }
}

.row3Skeleton {
  margin-top: 14px;

  @include desktop {
    margin-right: 10px;
    height: 10px;
    width: 148px;
  }

  @include mobile {
    margin-right: 5px;
    height: 10px;
    width: 98px;
  }
}
