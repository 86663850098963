
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.title {
  font-size: var(--font-size-s);
  font-weight: var(--font-weight-medium);
  pointer-events: none;
}

.content {
  display: grid;
  padding-left: 70px;
  grid-auto-flow: column;
  grid-gap: 12px;

  @include desktop {
    grid-template-rows: repeat(5, 28px);
  }

  @include mobile {
    width: max-content;
    grid-template-rows: repeat(8, 25px);
  }

  li {
    font-size: var(--font-size-xs);
  }
}
