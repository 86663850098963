
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.banner {
  overflow: hidden;
  @include mobile() {
    height: 200px;
  }
  @include desktop() {
    height: 278px;
    border-radius: 12px;
  }

  background-color: #f8f8f8;
  transition: all 0.6s;

  &:hover {
    box-shadow: var(--elevation-medium);
    background-color: white;
  }
}
