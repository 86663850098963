
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.banner {
  display: grid;
  align-items: center;

  @include mobile {
    padding: 0;
    min-width: 375px;
  }

  .viewport {
    overflow: hidden;
    position: relative;

    @include desktop {
      height: 162px;
    }
    @include mobile {
      height: 106px;
    }

    .strip {
      @include desktop {
        height: 112px;
        grid-template-columns: 1fr 3fr 1fr;
        font-size: 32px;
        border-radius: 8px;
      }
      @include mobile {
        height: 86px;
        grid-template-columns: 1fr 165px 1fr;
        font-size: var(--font-size-s);
      }
      background: linear-gradient(88.2deg, #f76b1c -35.2%, #fad961 125.79%);
      box-shadow: 0px 3px 10px rgba(255, 125, 0, 0.24);
      width: 100%;
      position: absolute;
      bottom: 0;
      color: rgb(var(--white));
      font-weight: var(--font-weight-medium);
      text-align: center;
      display: inline-grid;
      grid-auto-flow: column;
      grid-template-areas: 'mockup title buttons';
      align-items: center;

      .title {
        grid-area: title;
        max-width: 572px;
        justify-self: center;
      }

      .buttons {
        direction: ltr;
        grid-area: buttons;
        justify-self: end;
        display: flex;
        justify-content: space-between;

        .vertical {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
        }

        @include desktop {
          width: 200px;
          height: 80px;
          margin-left: 32px;
        }

        @include mobile {
          width: 70px;
          height: 46px;
          margin-left: 16px;
        }

        .storeButton {
          @include desktop {
            height: 34px;
          }
          @include mobile {
            width: 70px;
            height: 22px;
          }
        }

        .qrCode {
          border-radius: 8px;
          @include mobile {
            display: none;
          }
        }
      }
    }

    .appMockup {
      position: absolute;

      @include desktop {
        margin-right: 32px;
        transform: rotate(-8deg) translate(0, 4px);
      }
      @include mobile {
        margin-right: 8px;
        transform: rotate(-5deg) translate(0, -4px);
      }
    }
  }
}
