
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

@keyframes pop {
  0% {
    visibility: visible;
  }

  100% {
    visibility: hidden;
  }
}

.pop {
  animation-name: pop;
  animation-duration: 5s;
  animation-delay: 1s;
}

.hide {
  visibility: hidden;
}
