
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.banner {
  display: grid;
  align-items: center;

  @include mobile {
    padding: 0;
    min-width: 375px;
  }

  .viewport {
    overflow: hidden;
    position: relative;

    @include desktop {
      height: 140px;
    }
    @include mobile {
      height: 106px;
    }

    .strip {
      @include desktop {
        height: 112px;
        grid-template-columns: 1fr 3fr 1fr;
        font-size: 28px;
        border-radius: 8px;
      }
      @include mobile {
        height: 86px;
        grid-template-columns: 1fr 165px 1fr;
        font-size: var(--font-size-s);
      }
      background: linear-gradient(207.82deg, #0cd9e5 -9.98%, #0495ff 88.4%);
      box-shadow: 0px 3px 10px rgba(255, 125, 0, 0.24);
      width: 100%;
      position: absolute;
      bottom: 0;
      color: rgb(var(--white));
      font-weight: var(--font-weight-medium);
      text-align: center;
      display: inline-grid;
      grid-auto-flow: column;
      grid-template-areas: 'mockup title button';
      align-items: center;

      .title {
        grid-area: title;
        max-width: 514px;
        justify-self: center;

        @include mobile {
          text-align: start;
        }
      }

      .button {
        grid-area: button;
        display: grid;
        grid-auto-flow: column;
        padding: 8px;
        background: rgb(var(--white));
        border: 1px solid #0495ff;
        border-radius: 30px;
        align-content: center;

        @include desktop {
          width: 140px;
        }

        @include mobile {
          width: 84px;
          height: 25px;
          justify-self: end;
          margin-left: 21px;
        }

        span {
          color: #0495ff;
          font-weight: 500;

          @include desktop {
            font-size: var(--font-size-s);
          }
          @include mobile {
            font-size: var(--font-size-xxxs);
          }
        }
      }
    }

    .appMockup {
      grid-area: mockup;
      position: absolute;

      @include desktop {
        margin-right: 64px;
      }
    }
  }
}
