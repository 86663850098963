
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

@import './remove-details-marker.mixin.scss';

$itemsGap: 10px;
$contentTopMargin: 20px;

$itemsInlineMargin: $itemsGap/2;

.tabs {
  overflow: hidden;

  .tabContentSpacer {
    // background-color: aqua;
    margin-top: $contentTopMargin;
  }

  .tabList {
    display: flex;
    flex-direction: row;
    position: relative;
    margin-inline: -$itemsInlineMargin;

    .tabButton {
      @include remove-details-marker();
      font-size: 14px;
      border: 1px solid rgb(var(--pastelGrey));
      border-radius: 30px;
      background: rgb(var(--white));
      cursor: pointer;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-inline: $itemsInlineMargin;
      line-height: 1em;
      transition: all 0.3s;

      @include mobile {
        min-width: 75px;
      }
      @include desktop {
        min-width: 100px;
      }

      &:hover {
        border: 1px solid rgb(var(--spanishGrey));
      }
    }

    .tabItem {
      &[open] {
        & .tabButton {
          background: rgb(var(--safetyOrange));
          border: 1px solid rgb(var(--safetyOrange));
          color: rgb(var(--white));
          &:hover {
            background-color: rgb(var(--spanishOrange));
          }
        }

        & .tabContent {
          position: absolute;
          right: $itemsInlineMargin; // polyfill if inset-inline-start is not supported
          inset-inline-start: $itemsInlineMargin;
          margin-top: $contentTopMargin;
        }
      }
    }
  }
}
