
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.list {
  display: grid;
  gap: 12px;

  @include desktop {
    /*  layout: # # # # */
    grid-auto-flow: column;
    justify-content: space-between;
  }
  @media screen and (max-width: 1200px) {
    /*  layout: # #
                # #
    */
    grid-template-columns: repeat(2, min-content);
    grid-template-rows: repeat(2, min-content);
    justify-content: initial;
  }
  @include mobile {
    /*  layout: #
                #
                #
                #
    */
    grid-template-columns: none;
    grid-template-rows: none;
    grid-auto-flow: row;
  }

  .card {
    height: 80px;
    overflow: hidden;
    padding: 8px 14px;
    display: grid;
    align-items: center;
    grid-template-columns: 70px 1fr;

    @include mobile {
      width: auto;
    }

    .title {
      font-weight: var(--font-weight-medium);
      margin-bottom: 4px;
    }

    .description {
      $line: 1.4em;
      max-height: 2 * $line;
      line-height: $line;
      overflow: hidden;

      @include desktop {
        font-size: var(--font-size-xxs);
      }

      @include mobile {
        font-size: var(--font-size-xs);
      }
    }
  }
}
