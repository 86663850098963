
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.list {
  display: grid;

  @include desktop {
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
  }
  @include mobile {
    gap: 8px;
  }

  .card {
    display: grid;
    grid-template-columns: 35px 2fr auto;
    align-items: center;
    width: auto;
    margin: 0 !important;
    min-height: 50px;

    @include desktop {
      padding: 8px;
    }
    @include mobile {
      padding: 12px;
    }

    .text {
      font-size: var(--font-size-s);
    }

    .icon {
      @include iconLarge(rgb(var(--safetyOrange)));
    }

    .iconStroke {
      @include iconLarge(rgb(var(--safetyOrange)), true);
    }

    .chevronLeftIcon {
      @include iconCustom(22px);
    }
  }
}
