
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.list {
  display: grid;

  @include desktop {
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
  }
  @include mobile {
    gap: 8px;
  }
}
