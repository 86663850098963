
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

@import '@y2/mango/mixins';

.mobileOnly {
  @media #{$break2open} {
    display: none !important;
  }
}

.desktopOnly {
  @media #{$break1} {
    display: none !important;
  }
}

.dfpPlaceholder {
  .content {
    background-color: #ccc;
    overflow-wrap: break-word;
    direction: ltr;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    text-align: center;
    word-break: break-all;
    border: 2px solid black;
    font-family: 'Courier New', Courier, monospace;
  }
}
