
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.card {
  margin: 0 !important;
  margin-bottom: 8px !important;
  overflow: hidden;
  display: grid;

  @include desktop {
    height: 236px;
    grid-template-rows: 150px 1fr;
  }

  @include mobile {
    height: 160px;
    grid-template-rows: 95px 1fr;
  }

  .image {
    position: relative;

    @include desktop {
      height: 150px;
    }

    @include mobile {
      height: 95px;
    }
  }

  .text {
    @include desktop {
      padding: 12px 10px;
    }
    @include mobile {
      padding: 8px;
    }

    .title {
      font-weight: 400;

      @include desktop {
        font-size: var(--font-size-s);
      }
      @include mobile {
        font-size: var(--font-size-xxs);
      }
    }

    .description {
      @include desktop {
        font-size: var(--font-size-xs);
        margin-top: 8px;
      }

      @include mobile {
        font-size: var(--font-size-xxs);
        margin-top: 4px;
      }
    }
  }
}
