
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

$inlineMargin: 6px;

.card {
  border: 1px solid rgb(var(--isabelline));
  border-radius: 8px;
  margin: 0 $inlineMargin; // gap = 2 x $inlineMargin
  background-color: rgb(var(--white));
  display: block;
  overflow: auto;
  flex-shrink: 0;
  transition: all 0.3s;

  box-shadow: var(--elevation-small);
  &:hover {
    box-shadow: var(--elevation-medium);
    border: 1px solid rgb(var(--gainsboro));
  }

  &.variantA {
    @include mobile() {
      width: 140px;
    }
    @include desktop() {
      width: 290px;
    }
  }

  &.variantB {
    @include mobile() {
      width: 169px;
    }
    @include desktop() {
      width: 290px;
    }
  }
}

li {
  .card:first-child {
    margin-inline-start: 0;
  }
  .card:last-child {
    margin-inline-end: 0;
  }
}
