
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.header {
  display: flex;
  align-items: center;

  .title {
    font-weight: var(--font-weight-regular);

    @include desktop {
      font-size: var(--font-size-xl);
    }
    @include mobile {
      font-size: var(--font-size-m);
    }
  }
}

.tabs {
  @include desktop {
    margin-top: 20px;
  }
  @include mobile {
    margin-top: 15px;
  }
}

.skeletonImage {
  @include desktop {
    height: 150px;
    width: 290px;
  }

  @include mobile {
    height: 90px;
    width: 140px;
  }
}
