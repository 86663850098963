
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.container {
  background-image: url('#{$y2AssetsPath}yad2site/y2assets/images/pages/feed/feed_img_placeholder_small.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: block;
  position: relative;

  img {
    position: absolute;
    height: 100%;
    width: 100%;
  }
}
