
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.slide {
  position: absolute;
  inset: 0;
  margin: auto;

  @include desktop {
    width: 1200px;
  }
  @include mobile {
    img {
      object-fit: cover;
    }
  }
}
