
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.self {
  position: relative;
}

.viewport {
  overflow-x: scroll;

  &.scrollSnapActive {
    scroll-snap-type: x proximity;
  }

  &.hideScrollbar {
    scrollbar-width: none; // hide scrollbar in firefox
    &::-webkit-scrollbar {
      // hide scrollbar in chrome and safari
      display: none;
    }
  }

  ul {
    display: flex;
    margin: 0 calc(-1 * var(--carouselItemsMargin));
    // gap: 6px; // gap for flexbox is not supported in ios < 14.5 (https://caniuse.com/?search=gap)
    max-width: 100%;

    &.spaceBetween {
      justify-content: space-between;
    }

    li {
      flex-shrink: 0;
      margin: 0 var(--carouselItemsMargin);

      @include mobile {
        scroll-snap-align: center;
      }
      @include desktop {
        scroll-snap-align: start;
      }
    }
  }
}

.desktopOnly {
  @include mobile {
    display: none !important;
  }
}

.hideOverflow {
  overflow: hidden;
}
