
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.carousel {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .viewport {
    position: relative;
    width: 100%;
    height: 100%;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;

    .slideList {
      height: 100%;
      position: absolute;
      display: grid;
      grid-auto-flow: column;

      .slide {
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;
        scroll-snap-align: start;
      }
    }
  }

  .buttonList {
    position: absolute;
    display: grid;
    grid-auto-flow: column;

    @include mobile() {
      bottom: 2px;
      right: 24px;
    }
    @include desktop() {
      bottom: 24px;
      right: 48px;
    }

    li {
      margin-inline: -4px;

      .button {
        cursor: pointer;
        width: 16px;
        height: 16px;
        // background-color: aqua;
        display: grid;
        justify-items: center;
        align-items: center;
        margin: 8px;

        &::after {
          content: '';
          border: 1px solid #ff7100;
          border-radius: 100%;
          width: 8px;
          height: 8px;
          transition: all 0.2s;
        }

        &.active {
          cursor: default;

          &::after {
            background: #ff7100;
          }
        }
      }
    }
  }
}

.hideScrollbar {
  scrollbar-width: none; // hide scrollbar in firefox
  &::-webkit-scrollbar {
    // hide scrollbar in chrome and safari
    display: none;
  }
}
