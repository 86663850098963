
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.item {
  margin-inline: 0 !important;
  margin-bottom: 8px !important;
  overflow: hidden;

  display: grid;
  grid-template-areas:
    'image'
    'text';

  @include desktop {
    height: 236px;
    grid-template-rows: 150px 1fr;
  }
  @include mobile {
    height: 160px;
    grid-template-rows: 95px 1fr;
  }

  .image {
    grid-area: image;
    position: relative;
  }

  .text {
    grid-area: text;
    padding: 0 10px;
    display: grid;
    align-items: center;

    @include desktop {
      font-size: var(--font-size-s);
      line-height: 1.25rem;
    }
    @include mobile {
      font-size: var(--font-size-xxs);
      padding: 0 5px;
    }
  }
}
