
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.container {
  position: absolute;
  width: 100%;
  height: fit-content;
  margin-block: auto;
  inset: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: 1;
  pointer-events: none;

  button {
    margin-inline: -16px;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
    background-color: rgb(var(--white));
    padding: 4px;
    border-radius: 50%;
    font-size: var(--font-size-xl);
    transition: all 0.2s;
    pointer-events: auto;

    &:not(:disabled):hover {
      background-color: rgb(var(--snow));
    }

    svg {
      display: block;
    }

    &:disabled {
      opacity: 0.5;
      cursor: default;
    }
  }
}
