
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

$removeBtnSizeMobile: 48px;
$removeBtnSizeDesktop: 24px;
$removeBtnMargin: 4px;

.item {
  position: relative;

  &:hover .card {
    border: 1px solid #ccc;
  }

  .card {
    border: 1px solid rgb(var(--isabelline));
    border-radius: 8px;
    background-color: rgb(var(--white));
    display: block;
    padding: 8px;
    min-height: 96px;
    transition: all 0.2s;
    padding-inline-end: $removeBtnSizeMobile + $removeBtnMargin;
    font-weight: var(--font-weight-regular);
    font-size: var(--font-size-xxs);
    line-height: 1.5em;

    @include desktop {
      min-width: 120px;
      height: 100%;
      box-shadow: var(--elevation-medium);
    }
    @include mobile {
      width: 100%;
      min-height: 76px;
      box-shadow: var(--elevation-small);
    }

    .fieldsBox {
      display: flex;
      flex-flow: wrap;
      overflow: hidden;
    }

    .field {
      position: relative;

      &::before {
        content: '\00b7'; // https://www.toptal.com/designers/htmlarrows/punctuation/middle-dot/
        position: absolute;
        transform: translateX(0.5em);
      }

      &::after {
        content: '\00a0\00a0\00a0'; // https://www.toptal.com/designers/htmlarrows/punctuation/non-breaking-space/
      }
    }

    .label {
      color: rgb(var(--graniteGrey));
    }
  }

  .removeBtn {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    color: rgb(var(--spanishGrey));
    transition: all 0.2s;
    border-radius: 50%;

    font-size: 24px;
    line-height: 0.5em;

    &:hover {
      color: rgb(var(--jet));
      background-color: rgb(var(--isabelline));
    }

    @include mobile {
      margin: auto $removeBtnMargin;
      width: $removeBtnSizeMobile;
      height: $removeBtnSizeMobile;
    }
    @include desktop {
      margin: $removeBtnMargin;
      width: $removeBtnSizeDesktop;
      height: $removeBtnSizeDesktop;
    }
  }
}
