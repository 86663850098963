
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.container {
  width: 320px;
  padding: 28px 18px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    font-weight: var(--font-weight-medium);
    font-size: var(--font-size-xl);
    margin-top: 20px;
  }

  .content {
    width: 245px;
    font-weight: var(--font-weight-regular);
    font-size: var(--font-size-s);
    text-align: center;
    margin-top: 10px;
  }

  .button {
    width: 260px;
    height: 42px;
    margin-top: 30px;
  }
}
