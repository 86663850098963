
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.container {
  margin-block: 52px 0;
  background-color: rgb(var(--white));
  border-radius: 8px;
  outline: 1px solid rgb(var(--isabelline));
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);

  @include mobile {
    width: 100%;
  }

  &:hover .button {
    background-color: rgb(var(--bianca));
  }
}

.content {
  display: flex;
  flex-flow: column;
  gap: 1.5em;

  padding: 20px 30px;

  @include desktop {
    text-align: start;
  }
}

.title {
  font-size: var(--font-size-m);
}

.text {
  line-height: 1.5em;
  font-size: var(--font-size-xs);

  @include mobile {
    order: 2;
  }
}

.button {
  padding: 8px;
  border-radius: 30px;
  border: 1px solid rgb(var(--safetyOrange));
  transition: all 0.2s ease-in;
  color: rgb(var(--safetyOrange));
  font-size: var(--font-size-s);
  font-weight: 500;
  line-height: 1em;
  text-align: center;
}
