
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.DesktopPlasma {
  width: fit-content;
  margin: 60px auto;
}

.MobileStripTop {
  width: fit-content;
  margin: 8px auto;
}

.MobileBillboard {
  padding: 0 !important;
  display: flex;
  justify-content: center;

  & iframe {
    max-width: 100vw;
  }
}
