
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.button {
  display: flex;
  align-items: center;
  color: #ff7100;
  margin-right: 10px;
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);

  .iconButton {
    @include iconMedium();
  }

  .iconText {
    margin-right: 4px;
  }
}

.button:disabled {
  cursor: default;
}
