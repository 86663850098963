
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.item {
  position: relative;

  .likeButton {
    z-index: 1;
  }

  .card {
    margin-inline: 0 !important;
    margin-bottom: 8px !important;
    overflow: hidden;
    position: relative;

    .image {
      @include desktop {
        height: 150px;
      }

      @include mobile {
        height: 90px;
      }
    }

    .cardText {
      padding: 12px 10px 8px;

      * {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        height: 1em; // prevent height from collapsing when no text content
      }

      .cardHeader {
        font-weight: var(--font-weight-medium);
        font-size: var(--font-size-s);
      }

      .cardBody {
        font-size: var(--font-size-xs);
        margin-top: 4px;
      }

      .cardFooter {
        font-size: var(--font-size-xxs);
        margin-top: 12px;
      }

      @include mobile {
        .cardHeader {
          font-weight: var(--font-weight-medium);
          font-size: var(--font-size-xs);
        }

        .cardBody {
          font-size: var(--font-size-xxs);
        }

        .cardFooter {
          font-size: var(--font-size-xxs);
        }
      }
    }
  }
}
