
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.list {
  display: flex;

  @include mobile {
    overflow: auto;
    max-width: 100vw;
    scrollbar-width: none; // hide scrollbar in firefox
    &::-webkit-scrollbar {
      // hide scrollbar in chrome and safari
      display: none;
    }
  }
}
